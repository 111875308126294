import { CommonModule, DatePipe } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
// Required for Touch functionality of CRUX Components
import 'hammerjs';

import { AppComponent } from './app.component';
import { ComponentsModule } from './components/components.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FooterModule, NavbarModule } from '@crux/components';
import { CommonCommunicationService } from './services/common-communication.service';
import { DataService } from './services/data.service';
import { ProgressBarService } from './services/progress-bar.service';
import { AppRoutingModule } from './app-routing.module';
import { AuthGuard } from './guards/auth.guard';
import { MappingService } from './services/mapping.service';
import { NavbarComponent } from './components/navbar/navbar.component';
import {
  AnalyticsModule,
  GoogleTagManagerService,
  ApplicationInsightsService,
  CustomTimingService,
} from '@crux/services';

/**
 * ngrx store imports
 */
import { StoreModule } from '@ngrx/store';

import { environment } from '../environments/environment';
import { claimReducer } from './store/claim/reducers';
import { policyReducer } from './store/policy/reducers';
import { authReducer } from './store/auth/reducers';

@NgModule({
  declarations: [AppComponent, NavbarComponent],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    BrowserModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    // App Modules
    ComponentsModule,
    FooterModule,
    NavbarModule.forRoot({}),
    // Analytics Module for instrumenting events
    AnalyticsModule.forRoot(
      [GoogleTagManagerService, ApplicationInsightsService],
      {
        applicationInsights: {
          instrumentationKey: environment.appInsightsInstrumentationKey,
        },
      }
    ),
    /**
     * store imports
     */
    StoreModule.forRoot({
      claim: claimReducer,
      policy: policyReducer,
      auth: authReducer,
    }),
  ],
  providers: [
    CommonCommunicationService,
    DataService,
    ProgressBarService,
    MappingService,
    AuthGuard,
    DatePipe,
    CustomTimingService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

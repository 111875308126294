import { Injectable } from '@angular/core';
import { CruxHttpClientService } from '@crux/services';
import { environment } from '../../environments/environment';
import { HttpParams, HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { env } from 'process';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  constructor(
    private _http: CruxHttpClientService,
    public datepipe: DatePipe
  ) {}
  /**
   * Custom header
   */
  customHeaders = new HttpHeaders({
    apiVersion: '2.0',
    Src: 'DCP',
  });

  /* 
  * DCP Add Documents API call
  */
  uploadDocument(formValues, file, token): Observable<any> {
    let docHeader = new HttpHeaders({
      'content-type': 'application/json',
      apiVersion: '1',
      Authorization: 'Bearer ' + token,
    });
    console.log('docHeader-----', docHeader);

    return this._http.post(
      environment.apihost +
        environment.apis.uploadDocument.replace(
          '{claimnumber}',
          formValues.claimNumber
        ),
      {
        body: {
          attachmentDesc:
            formValues.contactLastName +
            ',' +
            formValues.contactFirstName +
            ',' +
            formValues.claimNumber +
            ',' +
            formValues.contactEmail,
          attachmentTypeCd: 'VehicleFirstNoticeOfLoss',
          mimeContentTypeCd: file.mimeType,
          notificationList: [
            {
              roleCd: 'CO',
            },
          ],
          attachmentFilename: file.fileName,
          attachmentData: file.fileContent,
        },
        headers: docHeader,
      }
    );
  }

  //sow2209
  docUploadMetaData(
    payload,
    bearerIdtoken,
    claimId,
    uploadAttachmentResponse
  ): Observable<any> {
    console.log('payload- docUploadMetaData', payload);
    console.log('bearerIdtoken- bearerIdtoken', bearerIdtoken);
    console.log('claimId---', claimId);
    console.log(
      'uploadAttachmentResponse------------------------',
      uploadAttachmentResponse
    );

    const docHeader = new HttpHeaders({
      Authorization: 'Bearer' + ' ' + bearerIdtoken,
      Apiversion: '1',
      'Ocp-Apim-Subscription-Key': environment.ApimSubscriptionKey,
    });
    const url = environment.apihost + environment.apis.docMetaDataUrl;

    return this._http.post(url, {
      body: {
        FileSource: 'ClaimDocUploadUI',
        Filename: uploadAttachmentResponse.documentName,
        PolicyNumber: '',
        ClaimNumber: claimId,
      },
      headers: docHeader,
    });
  }

  getTokenAPI(): Observable<any> {
    const url = environment.apihost + environment.apis.tokenApi;
    const headers = new HttpHeaders({
      App_ID: environment.tokenApiApp_ID,
      App_Key: environment.tokenApiApp_Key,
      apiVersion: '2',
      Resource: environment.tokenAPIResource,
      skip: 'true',
      isAuth: 'true',
    });

    return this._http.post(url, { headers: headers });
  }

  getAttachmentTokenAPI(): Observable<any> {
    const url = environment.apihost + environment.apis.tokenUrl;
    const headers = new HttpHeaders({
      App_ID: environment.appid,
      App_Key: environment.appkey,
      Resource: environment.resource,
    });

    return this._http.post(url, { headers: headers });
  }

  createInteractionAPICall(
    type: string,
    formValues,
    token,
    MetaData,
    ResponseData
  ): Observable<any> {
    let MetaDataa = MetaData ? JSON.stringify(MetaData) : '';
    let ResponseDataa = ResponseData ? JSON.stringify(ResponseData) : '';
    let currentDate = this.datepipe.transform(new Date(), 'MM/dd/yyyy');
    console.log('currentDate', currentDate);
    console.log(type);

    const docHeader = new HttpHeaders({
      Authorization: token,
      Apiversion: '1',
      Apikey: environment.interactionApikey,
      'Ocp-Apim-Subscription-Key': environment.interactionApimSubscriptionKey,
    });
    const url = environment.apihost + environment.apis.interactionApiUrl;

    return this._http.post(url, {
      body: {
        AgentFirstName: '',
        AgentLastName: '',
        Email: formValues.contactEmail,
        InteractionData: {
          CallDirection: 'Inbound',
          CallType: 'claimdoctype',
          CreatedBy: 'claimdoctype',
          Date: currentDate,
          Reason1: type,
          Reason2: `Claim Number: ${formValues}`,
          Reason3: `Meta Data: ${MetaDataa}`,
          Reason4: `Response Data: ${ResponseDataa}`,
          Title: type,
        },
        PolicyNumber: '',
        RefreshData: false,
        SessionId: '',
        worksiteAdmin: '',
      },
      headers: docHeader,
    });
  }
}

import { Component, OnInit, forwardRef, OnChanges, Input } from '@angular/core';
import {
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  ControlValueAccessor,
  Validator,
  FormControl,
  AbstractControl,
} from '@angular/forms';
import { DomHandler } from 'primeng/components/dom/domhandler';
import { FileUpload } from 'primeng/components/fileupload/fileupload';
import { map, sum } from 'lodash';

export function createFileValidator(validators) {
  return function validateFiles(c: FormControl) {
    if (!c.value) return null;

    let errors: {
      [key: string]: any;
    } = {};

    if (validators.msgs && validators.msgs.length)
      errors.msgs = validators.msgs;

    if (validators.maxFiles && c.value.length > +validators.maxFiles)
      errors.maxFiles = `A maximum of ${
        validators.maxFiles
      } files may be uploaded at the same time.`;

    if (
      validators.totalFilesize &&
      sum(map(c.value, 'size')) > validators.totalFilesize
    )
      errors.totalFilesize = `A total of ${validators.totalFilesize /
        (1024 * 1024)}MB may be uploaded at the same time.`;

    if (this.msgs) {
      this.msgs.map((err) => (errors.msgs = err.summary + err.detail));
    }
    return Object.keys(errors).length > 0 ? errors : null;
  };
}

@Component({
  selector: 'app-document-upload',
  templateUrl: './document-upload.component.html',
  styleUrls: ['./document-upload.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DocumentUploadComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DocumentUploadComponent),
      multi: true,
    },
    DomHandler,
  ],
})
export class DocumentUploadComponent extends FileUpload
  implements ControlValueAccessor, Validator, OnChanges {
  private changed = (_: any) => {};
  private touched = () => {};
  private validateFn: Function = () => {};

  @Input() chooseLabel: string = 'Browse for file';
  @Input() maxFiles: number;
  @Input() totalFilesize: number;
  @Input() showAccepts: boolean = false;
  @Input()
  accept: string =
    '.pdf,.doc,.docx,.xls,.xlsx,.csv,.mdb,.htm,.html,.mht,.jnt,.dds,image/*';

  ngOnChanges(changes) {
    if (changes.msgs || changes.maxFiles || changes.totalFilesize) {
      this.validateFn = createFileValidator({
        msgs: this.msgs,
        maxFiles: this.maxFiles,
        totalFilesize: this.totalFilesize,
      });
    }
  }

  writeValue(value: Array<File>) {
    this.files = value || [];
  }

  registerOnChange(fn) {
    this.changed = fn;
  }

  registerOnTouched(fn) {
    this.touched = fn;
  }

  onFileSelect(event) {
    super.onFileSelect(event);
    this.changed(this.files);
  }

  remove(event: Event, index: number) {
    super.remove(event, index);
    this.changed(this.files);
  }

  clear() {
    super.clear();
    this.changed(this.files);
  }

  validate(obj: File | AbstractControl): any {
    if (obj instanceof File) return super.validate(obj);

    return this.validateFn(obj);
  }
}

import { Claim } from '../../../classes/claim.class';

import { ClaimActionTypes, ClaimActionUnion } from './../actions';

export const InitialState: Claim = {
  contactFirstName: '',
  contactLastName: '',
  contactEmail: '',
  contactPhone: '',
  businessName: '',
  address1: '',
  zipCode: '',
  lossDate: '',
  description: '',
  isLawSuitFiled: '',
  documents: [],
};

export function claimReducer(
  state = InitialState,
  action: ClaimActionUnion
): Claim {
  switch (action.type) {
    case ClaimActionTypes.GetClaim: {
      return state;
    }
    case ClaimActionTypes.SetClaim: {
      return action.payload;
    }
    default: {
      return state;
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { CommonCommunicationService } from '../../services/common-communication.service';
import { AnalyticsService } from '@crux/services';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss'],
})
export class ConfirmationComponent implements OnInit {
  constructor(
    private cc: CommonCommunicationService,
    private _analytics: AnalyticsService
  ) {}

  claimNumber: string;

  ngOnInit() {
    /*
    * Analytics
    * Claim Details - Page view
    */
    this._analytics.eventTrack.next({
      category: 'Page',
      action: 'ClaimSubmission',
      label: 'Submission of FNOL',
      value: 'SUCCESS',
    });
    this.claimNumber = this.cc.claimNumber;
  }
}

import { Injectable } from '@angular/core';
import { CommonCommunicationService } from './common-communication.service';
import { Router } from '@angular/router';

@Injectable()
export class ProgressBarService {
  private _stepNumber = 1;
  get stepNumber(): number {
    return this._stepNumber;
  }
  set stepNumber(stepNum: number) {
    this._stepNumber = stepNum;
    this.steps.forEach((step) => {
      step.isTouched = step.associatedStep <= stepNum;
      step.showLine = step.associatedStep < stepNum;
      if (stepNum === step.associatedStep) {
        step.isCurrentSteps = true;
      } else {
        step.isCurrentSteps = false;
      }
    });
  }
  constructor(
    private ccs: CommonCommunicationService,
    private _router: Router
  ) {}

  public steps = [
    {
      name: 'Step 1:',
      title: 'Locate your policy',
      icon: 'step1_inactive',
      associatedUrl: '/locate',
      showLine: false,
      associatedStep: 1,
      isTouched: false,
      isCurrentSteps: false,
    },
    {
      name: 'Step 2:',
      title: 'Claim details',
      icon: 'step2_inactive',
      associatedUrl: '/claim',
      associatedStep: 2,
      showLine: false,
      isTouched: false,
      isCurrentSteps: false,
    },
    {
      name: 'Step 3:',
      title: 'Review your claim',
      icon: 'step3_inactive',
      associatedUrl: '/review',
      associatedStep: 3,
      showLine: false,
      isTouched: false,
      isCurrentSteps: false,
    },
  ];

  getCurrentUrl() {
    return this._router.url;
  }
  toggleView() {
    this.ccs.stepView = !this.ccs.stepView;
  }
  closeStepper() {
    this.ccs.stepView = false;
  }
}

import { Component } from '@angular/core';
import { NavbarService, NavItem } from '@crux/components';
import {
  GoogleTagManagerService,
  ApplicationInsightsService,
} from '@crux/services';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'app';
  headerLinks: NavItem[] = [
    {
      id: 'BusinessInsurance',
      name: 'Business Insurance',
      type: 'callback',
    },
    {
      id: 'Policyholders',
      name: 'Policyholders',
      type: 'callback',
    },
    {
      id: 'Help',
      name: 'Help',
      type: 'callback',
    },
    {
      id: 'AboutChubb',
      name: 'About Chubb',
      type: 'callback',
    },
    {
      id: 'phoneNum',
      name: '(844) 539-3801',
      iconPosition: 'before',
      iconName: 'phone',
      iconPath: '../../assets/icons/phone.svg',
      type: 'callback',
    },
  ];

  footer = {
    copyrightLabel: '©' + ' ' + new Date().getFullYear() + ' Chubb',
    copyrightText:
      'Chubb is the marketing name used to refer to subsidiaries of Chubb Limited providing insurance and related' +
      ' ' +
      'services described on this website. For a list of these subsidiaries, please visit our website at www.chubb.com.' +
      ' ' +
      'Insurance provided by ACE American Insurance Company and its U.S. based Chubb underwriting company affiliates.' +
      ' ' +
      'All products may not be available in all states. This communication contains product summaries only.' +
      ' ' +
      'Coverage is subject to the language of the policies as actually issued.' +
      ' ' +
      'Producer services for products on this website,' +
      ' ' +
      'including the sale of products are provided by Focus Insurance Services, LLC.',
    socialLinks: [
      {
        href: 'https://www.facebook.com/ChubbInsurance/?fref=ts',
        imgAlt: 'facebook',
        imgSrc: '../../assets/icons/facebook.svg',
      },
      {
        href: 'https://www.linkedin.com/company/chubb',
        imgAlt: 'linkedin',
        imgSrc: '../../assets/icons/linkedin.svg',
      },
      {
        href: 'https://twitter.com/ChubbNA',
        imgAlt: 'twitter',
        imgSrc: '../../assets/icons/twitter.svg',
      },
    ],
    links: [
      {
        id: 'Terms-of-Use',
        type: 'url',
        routeOrUrl: 'https://www.chubb.com/us-en/terms-of-use.aspx',
        label: 'Terms of Use',
      },
      {
        id: 'Licensing-Informationy',
        type: 'url',
        routeOrUrl:
          'https://www.chubb.com/_global-assets/documents/licensinginformation.pdf',
        label: 'Licensing Information',
      },
      {
        id: 'Privacy-Policy',
        type: 'url',
        routeOrUrl: 'https://www.chubb.com/us-en/online-privacy-policy.aspx',
        label: 'Privacy Policy',
      },
      {
        id: 'FATCA',
        type: 'url',
        routeOrUrl:
          'https://www2.chubb.com/us-en/tax-information-reporting-withholding.aspx',
        label: 'FATCA',
      },
      {
        id: 'NY-Domestic-Violence-Notice',
        type: 'url',
        routeOrUrl:
          'https://www2.chubb.com/us-en/important-notices/ny-domestic-violence.aspx',
        label: 'NY Domestic Violence Notice',
      },
      {
        id: 'Producer-Compensation-Legal-Disclosure',
        type: 'url',
        routeOrUrl:
          'https://www2.chubb.com/us-en/agents-brokers/producer-compensation.aspx',
        label: 'Producer Compensation Legal Disclosure',
      },
    ],
  };

  constructor(
    private _navbar: NavbarService,
    private _gtm: GoogleTagManagerService,
    private _ai: ApplicationInsightsService
  ) {}

  ngOnInit() {
    this._navbar.rightItems = this.headerLinks;
  }

  footerButtonClick(e): void {
    console.log(`Foot4er Button Click `, e);
  }

  onNavbarItemClick(e) {
    switch (e.id) {
      case 'BusinessInsurance':
        (<any>window).open(
          'https://www.chubb.com/us-en/business-insurance/',
          '_blank'
        );
        break;
      case 'AboutChubb':
        (<any>window).open(
          'https://www.chubb.com/us-en/about-chubb/who-we-are.aspx',
          '_blank'
        );
        break;
    }
  }
}

import {
  Input,
  Renderer2,
  HostListener,
  Directive,
  ElementRef,
  TemplateRef,
  ViewContainerRef,
  ContentChild,
} from '@angular/core';
@Directive({ selector: '[tooltipDirective]' })
export class TooltipDirective {
  private tooltipId: string;
  constructor(
    private renderer: Renderer2,
    private elementRef: ElementRef,
    private viewContainerRef: ViewContainerRef
  ) {}
  @Input() parametroPlantilla: TemplateRef<any>;
  @ContentChild('tooltipTemplate')
  private tooltipTemplateRef: TemplateRef<Object>;

  @HostListener('click')
  onmouseenter(): void {
    if (this.viewContainerRef && this.viewContainerRef.length > 0) {
      this.viewContainerRef.clear();
    } else {
      this.createTooltip();
    }
  }

  @HostListener('mouseenter')
  onMouseEnter(): void {
    this.createTooltip();
  }

  @HostListener('mouseleave')
  onMouseLeave(): void {
    if (this.viewContainerRef) {
      this.viewContainerRef.clear();
    }
  }

  createTooltip() {
    const view = this.viewContainerRef.createEmbeddedView(
      this.tooltipTemplateRef
    );
    view.rootNodes.forEach((node) =>
      this.renderer.appendChild(this.elementRef.nativeElement, node)
    );
  }
}

import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { IconModule, ButtonModule } from '@crux/components';

import { FormsModule } from '@angular/forms';
import { GeneralizedPopUpComponent } from './generalized-popup.component';

@NgModule({
  declarations: [GeneralizedPopUpComponent],
  imports: [
    CommonModule,
    MatFormFieldModule,
    FormsModule,
    MatInputModule,
    MatDialogModule,
    IconModule,
    ButtonModule,
  ],
  exports: [GeneralizedPopUpComponent],
  providers: [],
  entryComponents: [GeneralizedPopUpComponent],
})
export class MessagePopupModule {
  static rootComponent = GeneralizedPopUpComponent;
}

import { CommonCommunicationService } from './../../services/common-communication.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProgressBarService } from '../../services/progress-bar.service';

@Component({
  selector: 'app-content-body',
  templateUrl: './content-body.component.html',
  styleUrls: ['./content-body.component.scss'],
})
export class ContentBodyComponent implements OnInit {
  isAuthenticated: boolean;

  constructor(
    public cc: CommonCommunicationService,
    private pbs: ProgressBarService,
    private router: Router
  ) {}

  ngOnInit() {}

  public onStepClick(stepChangeEvent: any): void {
    if (stepChangeEvent.oldStepNumber > stepChangeEvent.newStepNumber) {
      this.router.navigateByUrl(stepChangeEvent.newUrl);
    }
  }
}

import { Policy } from '../../../classes/claim.class';

import { PolicyActionUnion, PolicyActionTypes } from '../actions';

export const InitialState: Policy = {
  PolicyNumber: '',
  PolicySubType: '',
  PolicyType: '',
  PolicyTypeName: '',
  Email: '',
  Phone: '',
  BusinessName: '',
  Address: {
    Address1: '',
    City: '',
    State: '',
    ZipCode: '',
  },
};

export function policyReducer(
  state = InitialState,
  action: PolicyActionUnion
): Policy {
  switch (action.type) {
    case PolicyActionTypes.GetPolicy: {
      return state;
    }
    case PolicyActionTypes.SetPolicy: {
      return action.payload;
    }
    default: {
      return state;
    }
  }
}

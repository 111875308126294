import { Action } from '@ngrx/store';

export enum AuthActionTypes {
  Authorize = '[ Auth ] Authorize Status',
  Unauthorize = '[Auth] Unauthorize Status',
}

export class authorizeAction implements Action {
  readonly type = AuthActionTypes.Authorize;
}

export class unauthorizeAction implements Action {
  readonly type = AuthActionTypes.Unauthorize;
}

export type AuthActionUnion = authorizeAction | unauthorizeAction;

import { FlexLayoutModule } from '@angular/flex-layout';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputMaskModule } from 'primeng/inputmask';

import {
  CardModule,
  InputModule,
  ButtonModule,
  CruxCalendarModule,
  ButtonToggleModule,
  SpinnerModule,
  ZipCodeModule,
  FormBuilderModule,
} from '@crux/components';
import { ContentBodyComponent } from './content-body/content-body.component';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { ReactiveFormsModule } from '@angular/forms';
import {
  MatStepperModule,
  MatIconModule,
  MatDialogModule,
  MatCheckboxModule,
} from '@angular/material';

import { CdkStepperModule } from '@angular/cdk/stepper';
import { TooltipDirective } from '../directive/tooltip.directive';
import { DialogComponent } from './dialog/dialog.component';
import { CruxHttpClientService, cruxHttpClient } from '@crux/services';
import { HttpClient } from '@angular/common/http';
import { DataService } from '../services/data.service';
import { RouterModule } from '@angular/router';
import { ClaimFbComponent } from './claim-fb/claim-fb.component';
import { FileUploadModule } from './file-upload/file-upload.module';
import { DocumentUploadModule } from './document-upload/document-upload.module';
import { MessagePopupModule } from './generalized-popup/generalized-popup.module';

@NgModule({
  imports: [
    ReactiveFormsModule,
    CommonModule,
    InputModule,
    ButtonModule,
    CruxCalendarModule,
    ButtonToggleModule,
    FlexLayoutModule,
    MatIconModule,
    CdkStepperModule,
    InputMaskModule,
    MatDialogModule,
    SpinnerModule,
    ZipCodeModule,
    // Component Modules
    CardModule,
    MatStepperModule,
    InputModule,
    //Ng Material
    MatCheckboxModule,
    RouterModule,
    //FormBuilder
    FormBuilderModule,
    FileUploadModule,
    DocumentUploadModule,
    MessagePopupModule,
  ],
  declarations: [
    ContentBodyComponent,
    ConfirmationComponent,
    TooltipDirective,
    DialogComponent,
    ClaimFbComponent,
  ],
  exports: [
    CardModule,
    ContentBodyComponent,
    ConfirmationComponent,
    InputModule,
    ButtonModule,
    CruxCalendarModule,
    ButtonToggleModule,
    InputMaskModule,
    MatDialogModule,
    SpinnerModule,
  ],
  providers: [
    {
      provide: CruxHttpClientService,
      useFactory: cruxHttpClient,
      deps: [HttpClient],
    },
    DataService,
  ],
  entryComponents: [DialogComponent],
})
export class ComponentsModule {}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ProgressBarModule } from 'primeng/progressbar';
import { MessagesModule } from 'primeng/messages';
import { ButtonModule } from 'primeng/button';
import { DocumentUploadComponent } from './document-upload.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ProgressBarModule,
    MessagesModule,
    ButtonModule,
  ],
  exports: [DocumentUploadComponent],
  declarations: [DocumentUploadComponent],
})
export class DocumentUploadModule {}

export class Mapper {
  static policyTypeMap = {
    '89': {
      '10': {
        name: "Business Owner's Policy",
        sourceSystem: 'SCI',
        displayName: "Business Owner's Policy",
        lossDesc: `Describe the property that has been damaged (e.g. your roof, contents, building exterior), how the damage occurred (e.g. water damage, fire, theft) and when/where the damage occurred. 
                  If you have photos of the damage, please attach them below.`,
        documentUpload:
          'You may want to attach: photos of damage, estimates or invoices.',
        lobCd: 'INMRC',
      },
    },
    '80': {
      '02': {
        name: 'Umbrella',
        sourceSystem: 'SCI',
        displayName: 'Umbrella',
        lossDesc: `Describe how, when and where the incident took place (e.g. a customer slipped and fell on your business premises). 
                  Identify any parties involved, including witnesses, and their relationship to you (e.g. employees, contractors, customers).
                  If anyone was injured as a result of the incident, please describe the injury type and severity (e.g. broken wrist, needed surgery).
                  If you have applicable accident reports, leases, property management agreements, or construction contracts, please attach them below; if a claim has been made against you, please attach a copy of the lawsuit or demand letter below.`,
        documentUpload:
          'You may want to attach: any applicable contracts, leases, invoices, accident reports, video footage or certificates of insurance.',
        lobCd: 'CGL',
      },
    },
    '09': {
      '08': {
        name: 'Cyber ERM Policy',
        sourceSystem: 'Micro Commercial',
        displayName: 'Cyber ERM Policy',
        lossDesc: `Describe the nature of the alleged incident (e.g. ransomware attack, phishing scheme, denial of service attack, data breach) and how you became aware of the incident.
                  Identify any parties involved and their relationship to you (e.g. employees, contractors, customers).
                  Identify potential consequences and monetary damages that may result from the alleged incident.
                  If applicable, be sure to attach a copy of the lawsuit or demand letter below.`,
        documentUpload:
          'You may want to attach: a lawsuit, demand letter or court documents received from a third party.',
        lobCd: 'PROF',
      },
      '09': {
        name: 'Miscellaneous Professional Liability Policy',
        sourceSystem: 'Micro Commercial',
        displayName: 'Miscellaneous Professional Liability Policy',
        lossDesc: `Describe the nature of the alleged incident (e.g. breach of contract, negligence, wrongful termination, sexual harassment) and how you became aware of the incident. 
                  Identify any parties involved and their relationship to you (e.g. employees, contractors, customers). 
                  Identify potential consequences and monetary damages that may result from the alleged incident. 
                  Be sure to attach a copy of the lawsuit or demand letter below.`,
        documentUpload:
          'You may want to attach: a lawsuit, demand letter or court documents received from a third party.',
        lobCd: 'PROF',
      },
      '06': {
        name: "Business Owner's Policy",
        sourceSystem: 'Micro Commercial',
        displayName: "Business Owner's Policy",
        lossDesc: `Describe the property that has been damaged (e.g. your roof, contents, building exterior), how the damage occurred (e.g. water damage, fire, theft) and when/where the damage occurred. 
                  If you have photos of the damage, please attach them below.`,
        documentUpload:
          'You may want to attach: photos of damage, estimates or invoices.',
        lobCd: 'INMRC',
      },
      '10': {
        name: 'Commercial General Liability Policy',
        sourceSystem: 'Micro Commercial',
        displayName: 'Commercial General Liability Policy',
        lossDesc: `Describe how, when and where the incident took place (e.g. a customer slipped and fell on your business premises). 
                  Identify any parties involved, including witnesses, and their relationship to you (e.g. employees, contractors, customers).
                  If anyone was injured as a result of the incident, please describe the injury type and severity (e.g. broken wrist, needed surgery).
                  If you have applicable accident reports, leases, property management agreements, or construction contracts, please attach them below; if a claim has been made against you, please attach a copy of the lawsuit or demand letter below.`,
        documentUpload:
          'You may want to attach: any applicable contracts, leases, invoices, accident reports, video footage or certificates of insurance.',
        lobCd: 'CGL',
      },
    },
    Other: {
      name: '',
      sourceSystem: '',
      displayName: '',
      lossDesc: `Describe the nature of the incident and any parties that were involved. Include the specific location where the incident occurred, if applicable. 
                If a claim has been made against you, provide a description of the potential damages.
                If anyone was injured as a result of the incident, please describe the injury type and severity.
                Attach any relevant documentation below (lawsuits, demand letters, photos of damage, accident reports, etc.).`,
      documentUpload:
        'You may want to attach: photos of damage, estimates, accident reports, lawsuits or demand letters.',
      lobCd: '',
    },
  };
}

/**
 * Previous policy name map
 */
// static policyTypeNameMap = {
//   MPL: 'Professional Liability Insurance',
//   ErrorsAndOmissions: 'Professional Liability Insurance',
//   BOP: "Business Owner's Policy",
//   GLI: 'General Liability Insurance',
//   Cyber: 'Cyber Insurance',
// };

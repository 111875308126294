export class UploadDocument {
  'AttachmentList': Array<{
    fileName: string;
    mimeType: string;
    fileContent: string;
  }>;

  static fromPolicy(fileList: File[]): Promise<UploadDocument> {
    let doc = new UploadDocument();
    doc.AttachmentList = [];
    let filePromises: Array<Promise<any>> = [];
    fileList.forEach((file) => {
      var reader = new FileReader();
      reader.readAsDataURL(file);

      let filePromise = new Promise((resolve, reject) => {
        reader.onload = (event: Event) => {
          if (!reader.result) resolve();

          let fileResults = reader.result.toString().split(';base64,');
          let attachment = {
            fileName: file.name,
            mimeType: fileResults[0].replace('data:', ''),
            fileContent: fileResults[1],
          };

          doc.AttachmentList.push(attachment);

          resolve();
        };
      });

      filePromises.push(filePromise);
    });

    let claimPromise = new Promise<UploadDocument>((resolve, reject) => {
      Promise.all(filePromises).then((results) => {
        resolve(doc);
      });
    });
    return claimPromise;
  }
}

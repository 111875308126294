/**
 * This entity contains the basic information required for all claims request messages.
 ** Root.ClaimsMsgRqInfo
 */
export class ClaimsMsgRqInfo {
  rqUID: string;
  transactionRequestDt: string;
  broadLOBCd: string;
  sourceSystem: string;
}

/**
 * This aggregate defines the details of a policy.
 ** Root.claimsNoticeInfo.Policy
 */
export class PolicyInfo {
  policyNumber: string;
  broadLOBCd: string;
  lobCd: string;
}

/**
 * The Address Aggregate is used wherever a postal address, including any portion of such address, is needed.
 ** Root.claimsNoticeInfo.ClaimsOccurrence.Addr
 */
export class Addr {
  addrTypeCd: string[];
  addr1: string;
  addr2: string;
  city: string;
  stateProvCd: string;
  postalCode: string;
  countryCd: string;
}

/**
 * This aggregate contains the basic data relative to an incident/occurrence.
 ** Root.claimsNoticeInfo.ClaimsOccurrence
 */
export class ClaimsOccurrence {
  lossDt: string;
  incidentDesc: string;
  addr: Addr[];
  litigationInd: boolean;
}

/**
 * Format for a person’s name, including various prefixes and suffixes.
 ** Root.claimsNoticeInfo.ClaimsParty.GeneralPartyInfo.NameInfo.PersonName
 */
export class PersonName {
  surname: string;
  givenName: string;
}

/**
 * A freeform text representation of the name of an organization or person.
 ** Root.claimsNoticeInfo.ClaimsParty.GeneralPartyInfo.NameInfo.CommlName
 */
export class CommlName {
  commercialName: string;
}

/**
 * The Name Information Aggregate allows for the ability to add a name to any person or organization.
 ** Root.claimsNoticeInfo.ClaimsParty.GeneralPartyInfo.NameInfo
 */
export class NameInfo {
  personName: PersonName;
  commlName: CommlName;
}

/**
 * The aggregate contains information relating to a phone number including the email address associated with some types of phones.
 ** Root.claimsNoticeInfo.ClaimsParty.GeneralPartyInfo.communications.PhoneInfo
 */
export class PhoneInfo {
  phoneTypeCd: string;
  phoneNumber: string;
}

/**
 * This aggregate contains the information relating to an email address.
 ** Root.claimsNoticeInfo.ClaimsParty.GeneralPartyInfo.communications.EmailInfo
 */
export class EmailInfo {
  emailAddr: string;
}

/**
 * This indicates ways to communicate with any party.
 ** Root.claimsNoticeInfo.ClaimsParty.GeneralPartyInfo.communications
 */
export class Communications {
  phoneInfo: PhoneInfo[];
  emailInfo: EmailInfo[];
}

/**
 * This aggregate provides general name, address and communications information about a person(s) or organization(s).
 ** Root.claimsNoticeInfo.ClaimsParty.GeneralPartyInfo
 */
export class GeneralPartyInfo {
  nameInfo: NameInfo[];
  communications: Communications;
}

/**
 * This aggregate provides additional information about a claims party.
 ** Root.claimsNoticeInfo.ClaimsParty.ClaimsPartyInfo
 */
export class ClaimsPartyInfo {
  claimsPartyRoleCd: string[];
}

/**
 * This aggregate contains information regarding any entity that has an “interest” or “involvement” in the claim, except for the adjuster.
 ** Root.claimsNoticeInfo.ClaimsParty
 */
export class ClaimsParty {
  generalPartyInfo: GeneralPartyInfo;
  claimsPartyInfo: ClaimsPartyInfo;
}

/**
 * This entity contains the basic information required for all claims notification messages.
 ** Root.claimsNoticeInfo
 */
export class ClaimsNoticeInfo {
  policy: PolicyInfo;
  claimsOccurrence: ClaimsOccurrence;
  claimsParty: ClaimsParty[];
}

import { CommonCommunicationService } from '../services/common-communication.service';
import { Injectable } from '@angular/core';
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { ProgressBarService } from '../services/progress-bar.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private cc: CommonCommunicationService,
    private _router: Router,
    private pbs: ProgressBarService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    /**
     * If user is not authenticated, redirect to locate page
     */
    if (!this.cc.isAuthenticated) {
      this._router.navigateByUrl('/locate');
    } else {
      /**
       * After claim submission, if user hits browser back button or try to go back to claim page,
       * then redirect to locate page
       */
      state.url === '/review' && this.cc.isSubmitted
        ? this._router.navigateByUrl('/locate')
        : null;
      return true;
    }

    /**
     * Navigate to locate when the above condition does not match
     */
    this._router.navigateByUrl('/locate');
    return false;
  }
}

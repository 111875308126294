/**
 * Angular Imports
 */
import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';

/**
 * Application Component Imports
 */
import { ConfirmationComponent } from './components/confirmation/confirmation.component';
import { ContentBodyComponent } from './components/content-body/content-body.component';

/**
 * Application Guards
 */
import { AuthGuard } from './guards/auth.guard';
import { ClaimFbComponent } from './components/claim-fb/claim-fb.component';

const appRoutes: Routes = [
  {
    path: '',
    component: ContentBodyComponent,
    children: [
      {
        path: '',
        redirectTo: 'claim',
        pathMatch: 'full',
      },
      {
        path: 'claim',
        component: ClaimFbComponent,
      },
    ],
  },

  {
    path: 'submitted',
    component: ConfirmationComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}

import { AuthActionUnion, AuthActionTypes } from '../actions';

export interface AuthState {
  isAuthenticated: boolean;
}

export const InitialState = {
  isAuthenticated: false,
};

export function authReducer(
  state = InitialState,
  action: AuthActionUnion
): AuthState {
  switch (action.type) {
    case AuthActionTypes.Authorize: {
      return {
        isAuthenticated: true,
      };
    }
    case AuthActionTypes.Authorize: {
      return {
        isAuthenticated: false,
      };
    }
    default: {
      return state;
    }
  }
}

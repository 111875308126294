import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import {
  ClaimsParty,
  GeneralPartyInfo,
  ClaimsPartyInfo,
  ClaimsMsgRqInfo,
  PolicyInfo,
  Addr,
  ClaimsOccurrence,
  ClaimsNoticeInfo,
} from '../classes/accord.class';
/**
 * Utility Imports
 */
import { v4 as uuid } from 'uuid';
import { isEqual } from 'lodash-es';
import { Claim, Policy } from '../classes/claim.class';
import { Mapper } from '../classes/mapper.class';

@Injectable({
  providedIn: 'root',
})
export class MappingService {
  claimsParty: any[] = [];

  constructor(private datePipe: DatePipe) {}

  /**
   * Function to create CreateFNOL API payload based on ACORD model
   */
  transformClaim(claim: Claim, policy: Policy) {
    /**
     * This entity contains the basic information required for all claims request messages.
     ** Root.ClaimsMsgRqInfo
     */
    const claimsMsgRqInfo = new ClaimsMsgRqInfo();
    claimsMsgRqInfo.rqUID = uuid();
    claimsMsgRqInfo.transactionRequestDt = new Date().toISOString();
    claimsMsgRqInfo.broadLOBCd = 'C';
    claimsMsgRqInfo.sourceSystem = 'CXP';

    /**
     * This aggregate defines the details of a policy.
     ** Root.claimsNoticeInfo.Policy
     */
    const policyDetails = new PolicyInfo();
    policyDetails.policyNumber = policy.PolicyNumber;
    policyDetails.broadLOBCd = 'C';
    policyDetails.lobCd =
      Mapper.policyTypeMap[policy.PolicyType][policy.PolicySubType].lobCd;

    /**
     * The Address Aggregate is used wherever a postal address, including any portion of such address, is needed.
     ** Root.claimsNoticeInfo.ClaimsOccurrence.Addr
     */
    const addr = new Addr();
    addr.addrTypeCd = ['LossAddress'];
    addr.addr1 = claim.address1;
    addr.addr2 = claim.address2;
    addr.city = claim.city;
    addr.stateProvCd = claim.state;
    addr.postalCode = claim.zipCode;
    addr.countryCd = 'US';

    /**
     * This aggregate contains the basic data relative to an incident/occurrence.
     ** Root.claimsNoticeInfo.ClaimsOccurrence
     */
    const claimOccurance = new ClaimsOccurrence();
    claimOccurance.lossDt = this.datePipe.transform(
      claim.lossDate,
      'yyyy-MM-dd'
    );
    claimOccurance.incidentDesc = claim.description;
    claimOccurance.addr = [addr];
    claimOccurance.litigationInd = isEqual(claim.isLawSuitFiled, 'Yes');

    /**
     * This aggregate contains information regarding any entity that has an “interest” or “involvement” in the claim, except for the adjuster.
     ** Root.claimsNoticeInfo.ClaimsParty
     ** Conditions are as follow:
     ** 1. Contact - Role Codes: ReptBy, CC
     * Mapping contract change - CLM-2257 - INCON instead of CC
     ** 2. Business Name that doesn't involves lawsuit and third party - Role Codes: Insured, CLM
     ** 3. Business Name that involves either lawsuit or third party - Role Codes: Insured
     ** 4. Law Suit filed by Organization - Role Codes: CLM
     ** 5. Law Suit filed by Individual - Role Codes: CLM
     */

    /**
     * Clear claims party to start fresh
     */
    this.clear();

    /**
     * Condition 1: Claims Party details for Contact
     */
    this.createClaimsParty(
      {
        firstName: claim.contactFirstName,
        lastName: claim.contactLastName,
        email: claim.contactEmail,
        phoneNumber: claim.contactPhone,
      },
      ['ReptBy', 'INCON']
    );

    /**
     * Condition 2: Claims Party details for Business Name that doesn't involves lawsuit and third party
     * third party involves only if law suit is not filed against you
     */
    if (claim.isThirdParty && isEqual(claim.isThirdParty, 'No')) {
      this.createClaimsParty(
        {
          commercialName: claim.businessName,
        },
        ['Insured', 'CLM']
      );
    } else {
      /**
       * Condition 3: Claims Party details for Business Name that involves either lawsuit or third party
       */
      this.createClaimsParty(
        {
          commercialName: claim.businessName,
        },
        ['Insured']
      );
    }

    /**
     * Condition 4: Claims Party details for Law Suit filed by Organization
     */
    if (isEqual(claim.filedBy, 'Organization')) {
      this.createClaimsParty(
        {
          commercialName: claim.organizationName,
        },
        ['CLM']
      );
    }

    /**
     * Condition 5: Claims Party details for Law Suit filed by third party individual
     */
    if (isEqual(claim.filedBy, 'Individual')) {
      this.createClaimsParty(
        {
          firstName: claim.individualFirstName,
          lastName: claim.individualLastName,
        },
        ['CLM']
      );
    }

    /**
     * This entity contains the basic information required for all claims notification messages.
     ** Root.claimsNoticeInfo
     */
    const claimsNoticeInfo = new ClaimsNoticeInfo();
    claimsNoticeInfo.policy = policyDetails;
    claimsNoticeInfo.claimsOccurrence = claimOccurance;
    claimsNoticeInfo.claimsParty = this.claimsParty;

    return {
      claimsMsgRqInfo: claimsMsgRqInfo,
      claimsNoticeInfo: claimsNoticeInfo,
    };
  }

  /**
   * Function to create claims party array
   */
  createClaimsParty(claimantDetails, roleCodes) {
    /**
     * This aggregate contains information regarding any entity that has an “interest” or “involvement” in the claim, except for the adjuster.
     ** Root.claimsNoticeInfo.ClaimsParty
     */
    const claimsPartyDetails = new ClaimsParty();
    claimsPartyDetails.generalPartyInfo = new GeneralPartyInfo();
    claimsPartyDetails.generalPartyInfo = {
      nameInfo: [
        {
          personName: {
            surname: claimantDetails.lastName,
            givenName: claimantDetails.firstName,
          },
          commlName: {
            commercialName: claimantDetails.commercialName,
          },
        },
      ],
      communications: {
        phoneInfo: [
          {
            phoneTypeCd: 'Phone',
            phoneNumber: claimantDetails.phoneNumber,
          },
        ],
        emailInfo: [
          {
            emailAddr: claimantDetails.email,
          },
        ],
      },
    };

    claimsPartyDetails.claimsPartyInfo = new ClaimsPartyInfo();
    claimsPartyDetails.claimsPartyInfo.claimsPartyRoleCd = [...roleCodes];

    this.claimsParty.push(claimsPartyDetails);
  }

  /**
   * Function to clear claims party array
   */
  clear() {
    this.claimsParty = [];
  }
}

import { Action } from '@ngrx/store';

import { Claim } from '../../../classes/claim.class';

export enum ClaimActionTypes {
  GetClaim = '[ get claim ] Get Claim',
  SetClaim = '[ set claim ] Set Claim',
}

export class getClaimAction implements Action {
  readonly type = ClaimActionTypes.GetClaim;
}

export class setClaimAction implements Action {
  readonly type = ClaimActionTypes.SetClaim;
  constructor(public payload: Claim) {}
}

export type ClaimActionUnion = getClaimAction | setClaimAction;

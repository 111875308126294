import { Action } from '@ngrx/store';

import { Policy } from '../../../classes/claim.class';

export enum PolicyActionTypes {
  GetPolicy = '[ get policy] Get Policy',
  SetPolicy = '[ set policy] Set Policy',
}

export class getPolicyAction implements Action {
  readonly type = PolicyActionTypes.GetPolicy;
}

export class setPolicyAction implements Action {
  readonly type = PolicyActionTypes.SetPolicy;
  constructor(public payload: Policy) {}
}

export type PolicyActionUnion = getPolicyAction | setPolicyAction;

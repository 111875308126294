import { Component, OnInit } from '@angular/core';
import { FormBuilderConfig } from '@crux/components/src/app/modules/form-builder/form-builder-config';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { CommonCommunicationService } from './../../services/common-communication.service';
import { DataService } from '../../services/data.service';
import { AnalyticsService } from '@crux/services';

import dataDictionary from './claim-dictionary.json';
import * as policy from 'assets/mock/policy.json';

import { FormBuilderInputControl, PostalCodeItem } from '@crux/components';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Claim, Policy } from '../../classes/claim.class';
import { setClaimAction } from '../../store/claim/actions';
import { AuthState } from '../../store/auth/reducers';
import { UploadDocument } from '../../classes/document-upload.class';
import { environment } from '../../../environments/environment';
import { HttpParams, HttpHeaders } from '@angular/common/http';
import { CruxHttpClientService } from '@crux/services';
import { catchError } from 'rxjs/operators';

import { HttpErrorResponse } from '@angular/common/http';

import { Observable, forkJoin, of, throwError } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { GeneralizedPopUpComponent } from '../generalized-popup/generalized-popup.component';
import { cloneDeep } from 'lodash-es';
import { take, timeout, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-claim-fb',
  templateUrl: './claim-fb.component.html',
  styleUrls: ['./claim-fb.component.scss'],
})
export class ClaimFbComponent implements OnInit {
  /**
   * Mock policy data
   */
  mockPolicy: any = policy;
  /**
   * Claim Dictionary
   */
  claimDictionary: any = dataDictionary.claim;
  /**
   * Form builder for How can we contact you?
   */
  contactFormBuilderConfig: FormBuilderConfig;
  isContactFormBuilderFormValid: boolean = false;
  uploadError = false;
  error: any;

  noFileUploaded = false;
  contactFormBuilderFormGroup: any;
  contactFormBuilderFormValues: any;
  contactFormBuilderSubmitter = new Subject<any>();
  /**
   * On Submit Form Validation flag
   */
  initValidationEnabled: boolean = false;
  /**
   * Policy data after transformation
   */
  policyData$: Policy;

  /**
   * zip code
   */
  postalCode: PostalCodeItem;
  /**
   * Claim data from NgRx store
   */
  claimDetail$: Claim;
  /**
   * Authentication
   */
  isAuthenticated$ = false;
  /**
   * Document upload
   */
  documents: File[] = [];
  /**
   * Document upload helper text
   */
  documentUploadText: string;

  tokenHeader: any;
  serverError = {
    height: 'auto',
    minHeight: '30vh',
    data: {
      header: 'Error',
      errorMessage:
        "We have encountered unexpected technical difficulties. We're working on it. Please try your request again later.",
      errorResponse: '',
      textOne: '',
      textTwo: '',
      removeButtonText: 'OK',
    },
    disableClose: true,
  };

  accessToken: any;
  claimNumber: any;
  noOfDocumentsToUpload: number;

  constructor(
    private dataService: DataService,
    public cc: CommonCommunicationService,
    private router: Router,
    private _store: Store<{ claim: Claim; policy: Policy; auth: AuthState }>,
    private _analytics: AnalyticsService,
    private _http: CruxHttpClientService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    console.log('V3-Prod');
    this.tokenHeader = new HttpHeaders({
      App_ID: environment.appid,
      App_Key: environment.appkey,
      Resource: environment.resource,
    });

    /*
    * Analytics
    * Claim Review - Page view
    */
    this._analytics.eventTrack.next({
      category: 'Page',
      action: 'ClaimDetails',
      label: 'Claim Details page',
    });

    /**
     * Init form builder call
     */
    this.initFormBuilder();
  }

  /**
   * Init form builder
   */
  initFormBuilder() {
    /**
     * Section for How can we contact you?
     */
    this.contactFormBuilderConfig = {
      showSubmit: false,
      controls: [
        {
          key: 'contactFirstName',
          name: 'First name',
          type: 'input',
          placeholder: '',
          label: "Policy Holder's First name (Optional)",
          layout: 'column',
          cssClass: 'contactFirstName',
          required: false,
          validationMessages: {
            required: 'Please enter a first name',
            pattern: 'Please enter a valid first name',
          },
          options: <FormBuilderInputControl>{
            type: 'text',
            appearance: 'legacy',
            floatLabel: 'never',
            hideRequiredMarker: true,
            maxlength: 60,
            customValidatorFns: [
              Validators.pattern(/^([A-Za-z]+[,.]?[ ]?|[A-Za-z]+['-]?)+$/),
            ],
          },
        },
        {
          key: 'contactLastName',
          name: 'Last name',
          type: 'input',
          placeholder: '',
          label: "Policy Holder's Last name (Optional)",
          layout: 'column',
          cssClass: 'contactLastName',
          required: false,
          validationMessages: {
            required: 'Please enter a last name',
            pattern: 'Please enter a valid last name',
          },
          options: <FormBuilderInputControl>{
            type: 'text',
            appearance: 'legacy',
            floatLabel: 'never',
            hideRequiredMarker: true,
            maxlength: 60,
            customValidatorFns: [
              Validators.pattern(/^([A-Za-z]+[,.]?[ ]?|[A-Za-z]+['-]?)+$/),
            ],
          },
        },
        {
          key: 'contactEmail',
          name: 'Email',
          type: 'input',
          placeholder: '',
          label: 'Your Email (Required)',
          defaultValue: '',
          required: true,
          validationMessages: {
            required: 'Please enter an email address',
            email: 'Please enter a valid email address',
            pattern: 'Please enter a valid email address',
          },
          options: <FormBuilderInputControl>{
            type: 'email',
            appearance: 'legacy',
            floatLabel: 'never',
            hideRequiredMarker: true,
            maxlength: 70,
            customValidatorFns: [
              Validators.pattern(
                '^[A-Za-z0-9._%+-]+@[^\\W\\s]+(\\.[A-Za-z]{2,4}){1,2}$'
              ),
            ],
          },
        },
        {
          key: 'claimNumber',
          name: 'Claim Number',
          type: 'input',
          placeholder: '',
          label: 'Claim number (Required)',
          defaultValue: '',
          required: true,
          validationMessages: {
            required: 'Please enter the claim number',
            pattern:
              'Claim number must be 8 digits and start with 2,4,5,6,7,8 or 9',
          },
          options: <FormBuilderInputControl>{
            type: 'text',
            appearance: 'legacy',
            floatLabel: 'never',
            hideRequiredMarker: true,
            maxlength: 8,
            customValidatorFns: [
              Validators.pattern(
                /^[2456789][0-9][0-9][0-9][0-9][0-9][0-9][0-9]$/
              ),
            ],
          },
        },
      ],
    };
  }

  /**
   * Validate form fields on submit
   */
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
        control.updateValueAndValidity({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  /**
   * Contact form value change function
   */
  contactFormValueChange(event) {
    this.contactFormSubmit(event);
  }

  /**
   * Contact form submit function
   */
  contactFormSubmit(formGroup: FormGroup) {
    this.isContactFormBuilderFormValid = formGroup.valid;
    this.contactFormBuilderFormGroup = formGroup;
    this.contactFormBuilderFormValues = formGroup.value;
  }

  createInteractionAPICall(
    type: string,
    formValues,
    token,
    attachmentMetaData,
    uploadAttachmentResponse
  ) {
    // console.log('token---',token)
    let bearertoken = 'Bearer ' + token['access_token'];
    this.dataService
      .createInteractionAPICall(
        type,
        formValues,
        bearertoken,
        attachmentMetaData,
        uploadAttachmentResponse
      )
      .subscribe({
        next: (createInteractionAPICallResponse) => {
          // console.log('createInteractionAPICallResponse - 1',createInteractionAPICallResponse)
        },
        error: (createInteractionAPICallError) => {
          // console.log('createInteractionAPICallError - 1',createInteractionAPICallError)
        },
      });
  }

  attachmentmetadataPayload(contactFormBuilderFormValues, attachmentData) {
    let attachmentMetaData = {
      attachmentDesc:
        contactFormBuilderFormValues.contactLastName +
        ',' +
        contactFormBuilderFormValues.contactFirstName +
        ',' +
        contactFormBuilderFormValues.claimNumber +
        ',' +
        contactFormBuilderFormValues.contactEmail,
      attachmentTypeCd: 'VehicleFirstNoticeOfLoss',
      mimeContentTypeCd: attachmentData.mimeType,
      notificationList: [
        {
          roleCd: 'CO',
        },
      ],
      attachmentFilename: attachmentData.fileName,
    };

    return attachmentMetaData;
  }

  /**
   * Review claim function
   */
  async reviewClaim(event) {
    this.uploadError = false;

    /**
     * Update form group status on load
     */
    this.contactFormBuilderSubmitter.next(event);
    /**
     * On Submit Form Validation flag
     */
    this.initValidationEnabled = true;
    /**
     * Condition to check form is valid or not
     */
    if (!this.isContactFormBuilderFormValid) {
      this.validateAllFormFields(this.contactFormBuilderFormGroup);
    } else {
      const noOfDocuments = this.documents.length;
      if (noOfDocuments > 0) {
        this.noFileUploaded = false;
        this.cc.showSpinner = true;
        /**
         * Form is valid
         */
        const formValues = {
          ...this.contactFormBuilderFormValues,
          documents: this.documents,
        };
        /**
         * NgRx store - set form data
         */
        this._store.dispatch(new setClaimAction(formValues));
        this.claimNumber = this.contactFormBuilderFormValues.claimNumber;
        // console.log('claimNumber ------',this.claimNumber)

        const files = [];
        const fileTransformationResult = await UploadDocument.fromPolicy(
          this.documents
        );
        // console.log('fileTransformationResult -----',fileTransformationResult,fileTransformationResult.AttachmentList.length);

        this.dataService.getTokenAPI().subscribe({
          next: (bearerIdentityToken) => {
            // console.log('bearerIdentityToken----',bearerIdentityToken)
            this.noOfDocumentsToUpload = 0;
            this.dataService.getAttachmentTokenAPI().subscribe({
              next: (attachmenttokenResponse) => {
                // console.log('attachmenttokenResponse->',attachmenttokenResponse)
                fileTransformationResult.AttachmentList.map(
                  (attachmentdatalist) => {
                    // console.log('attachmentdata-->',attachmentdatalist);
                    let attachmentMetaData = this.attachmentmetadataPayload(
                      this.contactFormBuilderFormValues,
                      attachmentdatalist
                    );
                    // console.log('attachmentMetaData ---->returned',attachmentMetaData)

                    this.dataService
                      .uploadDocument(
                        this.contactFormBuilderFormValues,
                        attachmentdatalist,
                        attachmenttokenResponse.access_token
                      )
                      .subscribe({
                        next: (uploadAttachmentResponse) => {
                          this.createInteractionAPICall(
                            `ClaimDocUpload- Upload Attachment API Success`,
                            this.claimNumber,
                            bearerIdentityToken,
                            attachmentMetaData,
                            uploadAttachmentResponse
                          );
                          let savemetaRawBody = {
                            FileSource: 'ClaimDocUploadUI',
                            Filename: uploadAttachmentResponse.documentName,
                            PolicyNumber: '',
                            ClaimNumber: this.claimNumber,
                          };
                          this.dataService
                            .docUploadMetaData(
                              attachmentdatalist,
                              bearerIdentityToken.access_token,
                              this.claimNumber,
                              uploadAttachmentResponse
                            )
                            .subscribe({
                              next: (docUploadMetaDataResponse) => {
                                if (
                                  docUploadMetaDataResponse != undefined &&
                                  docUploadMetaDataResponse['Code'] == '0000'
                                ) {
                                  this.createInteractionAPICall(
                                    `ClaimDocUpload - Meta Doc Upload Attachment API Success`,
                                    this.claimNumber,
                                    bearerIdentityToken,
                                    savemetaRawBody,
                                    docUploadMetaDataResponse
                                  );
                                } else {
                                  this.createInteractionAPICall(
                                    `ClaimDocUpload - Meta Doc Upload Attachment API Fail Error: ${JSON.stringify(
                                      docUploadMetaDataResponse
                                    )}`,
                                    this.claimNumber,
                                    bearerIdentityToken,
                                    savemetaRawBody,
                                    docUploadMetaDataResponse
                                  );
                                }
                              },
                              error: (docUploadMetaDataError) => {
                                this.createInteractionAPICall(
                                  `ClaimDocUpload- Meta Doc Upload Attachment API Fail Error: ${JSON.stringify(
                                    docUploadMetaDataError
                                  )}`,
                                  this.claimNumber,
                                  bearerIdentityToken,
                                  savemetaRawBody,
                                  ''
                                );
                              },
                            });
                          this.noOfDocumentsToUpload =
                            this.noOfDocumentsToUpload + 1;
                          if (
                            this.noOfDocumentsToUpload ==
                            fileTransformationResult.AttachmentList.length
                          ) {
                            this.cc.showSpinner = false;
                            this.router.navigateByUrl('/submitted');
                          }
                          // console.log('uploadAttachmentResponse',uploadAttachmentResponse)
                        },
                        error: (uploadAttachmentError) => {
                          this.createInteractionAPICall(
                            `ClaimDocUpload- Upload Attachment API Fail Error: ${JSON.stringify(
                              uploadAttachmentError
                            )}`,
                            this.claimNumber,
                            bearerIdentityToken,
                            attachmentMetaData,
                            ''
                          );
                          this.showSomethingWentWrongPopup(
                            this.formatApiError(uploadAttachmentError)
                          );
                          // console.log('uploadAttachmentError',uploadAttachmentError)
                        },
                      });
                  }
                );
              },
              error: (attachmenttokenError) => {
                this.createInteractionAPICall(
                  `ClaimDocUpload- Upload Attachment TOKEN API Fail Error: ${JSON.stringify(
                    attachmenttokenError
                  )}`,
                  this.claimNumber,
                  bearerIdentityToken,
                  '',
                  ''
                );
                this.cc.showSpinner = false;
                this.showSomethingWentWrongPopup(
                  this.formatApiError(attachmenttokenError)
                );
              },
            });
          },
          error: (bearerIdentityTokenError) => {
            console.log('Token Error : ', bearerIdentityTokenError);
          },
        });
      } else {
        this.noFileUploaded = true;
      }
    }
  }
  showSomethingWentWrongPopup(errorResponse) {
    const popUpData = cloneDeep(this.serverError);
    popUpData['data']['errorResponse'] = errorResponse;
    const popUp = this.dialog.open(GeneralizedPopUpComponent, popUpData);

    popUp.afterClosed().subscribe((event) => {
      this.cc.showSpinner = false;
    });
  }
  formatApiError(error) {
    if (error) {
      const message =
        error['url'] +
        ' ' +
        'Error: ' +
        error['status'] +
        ' ' +
        error['statusText'];
      return message;
    }
  }
  /**
   * On file select
   */
  onSelect(event) {
    this.documents = event.files;
  }
}

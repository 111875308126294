// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  apihost: 'https://nauat.chubbdigital.com',
  appid: '15f90575-e9c9-4dee-a5dc-f9e37b624840',
  appkey: '90mC_=Kg&=x+\\2m1b0nM_1AY',
  resource: '58d73703-4a4a-4eb2-be37-08137ac19b4b',
  apis: {
    uploadDocument: '/claims.servicing.attachments/{claimnumber}/attachments/',
    tokenUrl: '/api/v1/tokens/',
    docMetaDataUrl: '/cica.operations.cicaselfservice/DocMetaDataSave',
    tokenApi: '/api/tokens/?Identity=AAD',
    interactionApiUrl:
      '/cica.operations.cicaselfservice/claims/sqlw/interactions',
  },
  gtmTrackingId: 'GTM-WW3F2JZ',
  appInsightsInstrumentationKey: 'ee626cb1-b8d5-4be7-ac7c-f843a3a7e60b',
  tokenApiApp_ID: '22841036-2507-45f1-8f11-ecfbfb27c98e',
  tokenApiApp_Key: 'mAI34g~5QK.2JKBkVyzoqFb0lJGP~l_F_O',
  tokenAPIResource: '4d78f778-2ca3-4f32-9d0e-8d5e2fa6259e',
  ApimSubscriptionKey: 'b899a0158cb3472ba05fa68fed912e15',
  interactionApikey: 'd1e6c9f6-17a8-4ee3-b739-8d5b5dfd5840',
  interactionApimSubscriptionKey: 'b899a0158cb3472ba05fa68fed912e15',
};

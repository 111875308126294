import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CommonCommunicationService {
  constructor() {}
  /* 
  * Default Authentication mode
  */
  public isAuthenticated: boolean = false;
  public isSubmitted: boolean = false;

  /* 
  * Navbar phone number and link
  */
  public navPhoneNumber: string = '(844) 539-3801';
  public navPhoneNumberLink: string = 'tel:+18445393801';

  /* 
  * Form values
  */
  public policyNumber: string;
  public email: string;
  public policyResponse: any;
  public policyType: string;
  public policyTypeName: string;

  public showSpinner: boolean = false;

  public stepView: boolean = false;

  public claimNumber: string;

  public reset() {
    this.isAuthenticated = false;
    this.policyNumber = '';
    this.email = '';
    this.policyResponse = undefined;
    this.policyType = '';
  }
}

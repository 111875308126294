import { Component, OnInit, HostListener } from '@angular/core';
import { CommonCommunicationService } from '../../services/common-communication.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  public logo: string = '../../assets/logos/combined_logo.png';

  public isActive: boolean = false;

  notHidden = true;

  constructor(public cc: CommonCommunicationService, public router: Router) {
    router.events.subscribe((event: any) => {
      if (event.url === undefined) {
      } else if (event.url.includes('download')) {
        this.notHidden = false;
      } else {
        this.notHidden = true;
      }
    });
  }

  ngOnInit() {
    const items = document.getElementsByClassName('menu-item-has-children');
  }

  // toggleDropDown(): void {
  //   document.getElementById('menuItems').classList.toggle('show');
  // }

  // @HostListener('document:click', ['$event'])
  // clickout(event) {
  //   if (!event.target.matches('.toggle')) {
  //     var items = document.getElementsByClassName('drop-down-content');
  //     for (let i = 0; i < items.length; i++) {
  //       var openItems = items[i];
  //       if (openItems.classList.contains('show')) {
  //         openItems.classList.remove('show');
  //       }
  //     }
  //   }
  // }
}
